<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="5" :md="12" :sm="24" :xs="24">
            <a-form-item label="单据编号:">
              <a-input
                v-model:value.trim="where.code"
                placeholder="请输入单据编号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="4" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div style="padding: 10px 0">
        <a-space>
          <a-button type="primary" @click="openEdit()">
            <template #icon>
              <plus-outlined />
            </template>
            <span>新建</span>
          </a-button>
        </a-space>
      </div>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
      >
        <template #action="{ record }">
          <a-space>
            <a @click="openApprove(record)">审批信息</a>
            <a-divider type="vertical" />
            <a @click="openAssets(record)">资产列表</a>
            <a-divider
              type="vertical"
              v-if="
                record.applicant === loginUser.userId &&
                record.flowState === '进行中'
              "
            />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a
                v-if="
                  record.applicant === loginUser.userId &&
                  record.flowState === '进行中'
                "
                class="ud-text-danger"
                >删除</a
              >
            </a-popconfirm>
            <a-divider type="vertical" />
            <a @click="openDoc(record)">单据下载</a>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑弹窗 -->
    <IndexFormEdit
      v-model:visible="showEdit"
      :data="current"
      :organization-list="organizationList"
      :warehouse-list="warehouseList"
      :category-data="categoryData"
      @done="reload"
    />

    <FormAssetsList v-model:visible="showAssets" :data="currentAssets" />
    <ApproveModal
      v-model:visible="showApprove"
      :data="currentApprove"
      @done="reload"
    />
    <DownloadDoc v-model:visible="showDoc" :data="currentDoc" @done='reload'/>
  </div>
</template>

<script>
import * as formInfoApi from '@/api/ams/formInfo'
import * as organizationApi from '@/api/sys/organization'
import * as warehouseApi from '@/api/ams/warehouse'
import * as assetsCategoryApi from '@/api/ams/assetsCategory'
import IndexFormEdit from './index-form.vue'
import FormAssetsList from './assets.vue'
import ApproveModal from './application.vue'
import DownloadDoc from './downloadDoc.vue'
import { createVNode } from 'vue'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  name: 'withdrawalIndex',
  components: {
    PlusOutlined,
    IndexFormEdit,
    FormAssetsList,
    ApproveModal,
    DownloadDoc
  },
  props: {
    isRadio: {
      type: String,
      default: 'checkbox'
    }
  },
  computed: {
    // 当前登录用户信息
    loginUser() {
      return this.$store.state.user.user
    }
  },
  data() {
    return {
      name: 'warehousingIndex',
      // 表格列配置
      columns: [
        {
          width: 48,
          align: 'center',
          fixed: 'left',
          customRender: ({ index }) => index + 1
        },
        {
          title: '单据编号',
          align: 'center',
          fixed: 'left',
          dataIndex: 'code',
          sorter: true
        },
        {
          title: '单据状态',
          align: 'center',
          dataIndex: 'flowState',
          sorter: true
        },
        {
          title: '申请人',
          align: 'center',
          dataIndex: 'applicantName',
          sorter: true
        },
        {
          title: '申请部门',
          align: 'center',
          dataIndex: 'departmentName',
          sorter: true
        },
        {
          title: '申请时间',
          align: 'center',
          dataIndex: 'createTime',
          sorter: true
        },
        {
          title: '备注',
          align: 'center',
          dataIndex: 'remark',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          fixed: 'right',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 表格排序条件
      order: { sort: 'createTime', order: 'desc' },
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showInfo: false,
      // 当前编辑数据
      current: null,
      currentAssets: null,
      currentApprove: null,
      currentDoc: null,
      // 编辑表单提交状态
      editLoading: false,
      // 组织结构树
      organizationList: [],
      // 公物仓列表
      warehouseList: [],
      // 是否显示资产列表
      showAssets: false,
      // 是否显示审批信息
      showApprove: false,
      // 是否显示单据下载信息
      showDoc: false
    }
  },
  created() {
    // this.getOrganizationList()
    this.getWarehouseData()
    // this.getCategoryData()
  },
  methods: {
    datasource(option, callback) {
      this.where.flowId = 5
      formInfoApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.form = {}
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      formInfoApi
        .deleteById(row.id)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.id) }
          formInfoApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    /* 打开详情弹窗 */
    openDetail(row) {
      this.current = row
      this.showInfo = true
    },
    openAssets(row) {
      this.currentAssets = row
      this.showAssets = true
    },
    openApprove(row) {
      this.currentApprove = row
      this.showApprove = true
    },
    openDoc(row) {
      this.currentDoc = row
      this.showDoc = true
    },
    getOrganizationList() {
      organizationApi
        .all({
          organizationType: 'unit'
        })
        .then((res) => {
          if (res.code === 0) {
            this.organizationList = res.data
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    getCategoryData() {
      assetsCategoryApi.tree().then((res) => {
        if (res.code === 0) {
          this.categoryData = res.data
        }
      })
    },
    // 获取公物仓
    getWarehouseData() {
      warehouseApi
        .all({ unit: this.$store.state.user.user.unitId })
        .then((res) => {
          if (res.code === 0) {
            this.warehouseList = res.data
          }
        })
    }
  }
}
</script>

<style scoped>
</style>
